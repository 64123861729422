/* eslint-disable comma-dangle */

const routePattern = (orgnummer: string, year: number | string, tertialNr: number | string, path: string) =>
  `${orgnummer}/${path}/${year}/${tertialNr}`;

export default {
  home: (orgnummer: string, year: number | string, tertialNr: number | string) => `${orgnummer}/${year}/${tertialNr}`,
  tilskuddsordninger: (orgnummer: string, year: number | string, tertialNr: number | string) =>
    routePattern(orgnummer, year, tertialNr, "tilskudd"),
  driftOgProsjekter: (orgnummer: string, year: number | string, tertialNr: number | string) =>
    routePattern(orgnummer, year, tertialNr, "prosjekter"),
  sRapport: (orgnummer: string, year: number | string, tertialNr: number | string) => routePattern(orgnummer, year, tertialNr, "s-rapport"),
  signInUidp: "signin",
  udirOm: "https://www.udir.no/om-udir/",
  udirKontaktinfo: "https://www.udir.no/om-udir/annen-kontaktinformasjon/",
  personvern: "personvern",
  chooseStatsforvalter: "velg-statsforvalter",
};
