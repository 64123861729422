import React from "react";
import styled from "styled-components";
import { Tabs, Tab } from "@utdanningsdirektoratet/tabs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import { Icon } from "@utdanningsdirektoratet/icon";
import { TertialClient } from "../ApiClients";
import { getTertialsKey } from "../utils/swrUtils";

const Wrapper = styled.div`
  width: 100%;
  display: block;
`;

const getLabel = (tertialNr: number, isOpen: boolean): React.ReactNode => {
  if (isOpen) {
    return (
      <>
        Tertial {tertialNr}
        <Icon icon="unlock" placement="after" />
      </>
    );
  }
  return `Tertial ${tertialNr}`;
};

const TertialTabs: React.FC = () => {
  const { year, tertialNr, orgnummer } = useParams() as { orgnummer: string; year: string; tertialNr: string };
  const navigate = useNavigate();
  const location = useLocation();

  const { data: tertials } = useSWRImmutable(getTertialsKey(year), () =>
    new TertialClient().getTertialsForStatsforvalter(+year, orgnummer)
  );

  const currentLocation = location.pathname;

  if (!tertialNr) return null;

  const tertial1IsOpen = tertials?.find((t) => t.tertial === 1)?.isOpen ?? false;
  const tertial2IsOpen = tertials?.find((t) => t.tertial === 2)?.isOpen ?? false;
  const tertial3IsOpen = tertials?.find((t) => t.tertial === 3)?.isOpen ?? false;

  const onTertialChange = (nr: number) => {
    const newUrl = currentLocation.replace(/\/\d{1}$/, `/${nr}`);
    navigate(newUrl);
  };

  return (
    <Wrapper>
      <Tabs outline>
        <Tab
          id="tertial-1"
          label={getLabel(1, tertial1IsOpen)}
          active={+tertialNr === 1}
          onClick={() => onTertialChange(1)}
        />
        <Tab
          id="tertial-2"
          label={getLabel(2, tertial2IsOpen)}
          active={+tertialNr === 2}
          onClick={() => onTertialChange(2)}
        />
        <Tab
          id="tertial-3"
          label={getLabel(3, tertial3IsOpen)}
          active={+tertialNr === 3}
          onClick={() => onTertialChange(3)}
        />
      </Tabs>
    </Wrapper>
  );
};

export default TertialTabs;
