import type {
  AntiForgeryTokenInfo,
  LoggedInPortalUserViewModel,
  PreloadedViewModelOfLoggedInPortalUserViewModel,
} from "./ApiClients";

declare global {
  interface Window {
    initialState: PreloadedViewModelOfLoggedInPortalUserViewModel;
  }
}
export const getAntiForgeryTokenInfo = (): AntiForgeryTokenInfo => window.initialState.antiForgeryTokenInfo;
export const getCurrentUser = (): LoggedInPortalUserViewModel | undefined => window.initialState.currentUser;
export const getSentryId = (): string | undefined => window.initialState.sentryId ?? undefined;

