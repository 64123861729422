import type { ProblemDetails, ValidationProblemDetails } from "../ApiClients";

export const getTertialDeadline = (tertialNr: number): string => {
  if (tertialNr === 1) {
    return "30. april";
  }
  if (tertialNr === 2) {
    return "31. august";
  }
  if (tertialNr === 3) {
    return "31. desember";
  }

  return "";
};

export function isProblemDetails(error: unknown): error is ProblemDetails {
  return error != null && typeof error === "object" && "status" in error && "type" in error;
}

export function isValidationProblemDetails(error: unknown): error is ValidationProblemDetails {
  return isProblemDetails(error) && "errors" in error && typeof error.errors === "object";
}
export const statsforvalterByFylkeSort = (a: string, b: string) => {
  const fylkeA = a.substring(18); // Henter fylkesnavnet fra a
  const fylkeB = b.substring(18); // Henter fylkesnavnet fra b
  return fylkeA.localeCompare(fylkeB);
};

