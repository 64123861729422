import { Link as UdirLink } from "@utdanningsdirektoratet/link";
import { Link } from "react-router-dom";

interface BackNavigationProps {
  to: string;
}

export const BackNavigation: React.FC<BackNavigationProps> = ({ to }) => {
  return (
    <UdirLink iconLeft="angle left" text="Tilbake">
      {(props) => <Link {...props} to={to} />}
    </UdirLink>
  );
};
