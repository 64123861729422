import React from "react";
import styled from "styled-components";
import { formatDate } from "@utdanningsdirektoratet/utils/date";

type LastSavedProps = {
  date: Date | string | undefined;
};

const Wrapper = styled.div`
  margin-bottom: 1rem;
`;

export const LastSaved: React.FC<LastSavedProps> = ({ date }) => {
  if (date === undefined) return null;
  return <Wrapper>Sist lagret: {formatDate(date.toString(), "d. MMMM yyyy 'kl' HH:mm")}</Wrapper>;
};
