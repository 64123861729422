import styled from "styled-components";

export const CustomTable = styled.table<{ disabled: boolean }>`
  border-collapse: collapse;

  caption {
    caption-side: bottom;
    padding: 10px;
  }

  thead tr {
    border-bottom: 0.125rem solid var(--bordergrey);
    text-align: left;
  }

  thead th {
    min-width: 8rem;
  }
  tbody {
    tr {
      height: 2.5rem;
      background: var(--white);
      transition: all linear 0.1s;
      &:nth-child(2n) {
        background-color: var(--offwhite);
      }
      &:hover {
        background-color: ${(p) => (p.disabled ? "" : "var(--steelblue4)")};
      }
      td {
        transition: all linear 0.2s;
        white-space: nowrap;
      }
    }
  }

  td {
    height: 2.5rem;
    padding: 0.5rem 1rem;
  }
`;
