import { SWRConfiguration } from "swr";

// Keys
export const GetStatsforvaltereKey = () => "getStatsforvaltere";
export const getTertialsKey = (year: string | number) => `getTertial-${year}`;
export const getTertialKey = (year: string | number, tertialNr: string | number) => `getTertial-${year}-${tertialNr}`;
export const getStatsforvalterTertialKey = (orgnummer: string, year: string | number, tertialNr: string | number) =>
  `getStatsforvalterTertial-${orgnummer}-${year}-${tertialNr}`;

// Config
export const HFLSWRStandardOptions: SWRConfiguration = { errorRetryCount: 0, revalidateOnFocus: false };
