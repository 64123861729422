import { Card } from "@utdanningsdirektoratet/card";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import React from "react";
import { Placeholder } from "@utdanningsdirektoratet/placeholder";
import { Button, LinkButton } from "@utdanningsdirektoratet/button";
import { Icon } from "@utdanningsdirektoratet/icon";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import useSWRImmutable from "swr/immutable";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { Heading2, Heading3 } from "@utdanningsdirektoratet/styles";
import { Popin, usePopin } from "@utdanningsdirektoratet/popin";
import { getStatsforvalterTertialKey, getTertialKey, HFLSWRStandardOptions } from "../utils/swrUtils";
import { RapportClient, RapportStatus, StatsforvalterTertialClient, TertialClient, TextClient } from "../ApiClients";
import routes from "../routes";

export const CenterWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  max-width: 60rem;
`;

export const StyledCard = styled(Card)`
  min-width: 19rem;
` as typeof Card;

export const TextContainer = styled.div`
  display: flex;
  max-width: 60rem;
  padding-bottom: 2rem;
`;

const getColor = (status: RapportStatus | undefined) => {
  if (status === RapportStatus.Approved) return "green";
  if (status === RapportStatus.Draft) return "yellow";
  if (status === RapportStatus.Irrelevant) return "lightgrey";
  return "lightazure";
};

export const Forside: React.FC = () => {
  const { orgnummer, year, tertialNr } = useParams() as { orgnummer: string; year: string; tertialNr: string };
  const { data: forsideText } = useSWR("getTexts", () => new TextClient().getForsideText(), HFLSWRStandardOptions);
  const { data: rapportStatuser, isLoading } = useSWR(
    `getStatus-${orgnummer}-${year}-${tertialNr}`,
    () => new RapportClient().getAllStatuses(orgnummer, +year, +tertialNr),
    HFLSWRStandardOptions
  );
  const { data: tertial } = useSWRImmutable(getTertialKey(year, tertialNr), () => new TertialClient().getTertial(+year, +tertialNr));

  const { data: statsforvalterTertial, mutate: mutateStatsforvalterTertial } = useSWR(
    getStatsforvalterTertialKey(orgnummer, year, tertialNr),
    () => new StatsforvalterTertialClient().get(orgnummer, +year, +tertialNr),
    HFLSWRStandardOptions
  );

  const [sendInnRapportConfirmationPopinId, showPopin, hidePopin] = usePopin();

  const sendInn = async () => {
    try {
      const client = new StatsforvalterTertialClient();
      await client.submit(orgnummer, +year, +tertialNr);
      await mutateStatsforvalterTertial();
      newNotification("success", "Innsending vellykket");
    } catch (e) {
      /* empty */
    } finally {
      hidePopin();
    }
  };

  const getStatusTexts = (status: RapportStatus | undefined) => {
    if (status === RapportStatus.Approved) return "Godkjent";
    if (status === RapportStatus.Draft) return "Påbegynt, ikke godkjent";
    if (status === RapportStatus.Irrelevant) return "Ingenting å rapportere";
    if (
      tertial?.open === true &&
      status !== undefined &&
      (status as RapportStatus) !== RapportStatus.Approved &&
      (status as RapportStatus) !== RapportStatus.Draft
    )
      return "Åpen for rapportering";
    if (tertial?.open === false && status === RapportStatus.Closed) return "Ikke godkjent";
    if (tertial?.open === false) return "Ikke åpen for rapportering";
    return "Ikke godkjent";
  };

  // Kan sende inn dersom det er 1 tertial og en av rapportene er godkjent
  const firstTertial =
    +tertialNr === 1 &&
    (rapportStatuser?.SRapport === RapportStatus.Approved ||
      rapportStatuser?.DriftOgProsjekter === RapportStatus.Approved ||
      rapportStatuser?.Tilskuddsordninger === RapportStatus.Approved);

  // For 2 og 3 tertial må alle rapportene være godkjent/irrelevant.
  const otherTertials =
    rapportStatuser?.SRapport === RapportStatus.Approved &&
    (rapportStatuser.DriftOgProsjekter === RapportStatus.Approved || rapportStatuser.DriftOgProsjekter === RapportStatus.Irrelevant) &&
    (rapportStatuser.Tilskuddsordninger === RapportStatus.Approved || rapportStatuser.Tilskuddsordninger === RapportStatus.Irrelevant);

  const disableInnsending = !(tertial?.open && (firstTertial || otherTertials));

  const disabledCard = !tertial?.open && tertial?.closedDate === null;

  return (
    <MainPanel color="white">
      <CenterWrapper>
        <Heading2 className="u--marginBottom2">Tertial {tertial?.tertialNr}</Heading2>
        <TextContainer>
          <RichTextView html={forsideText?.content ?? ""} document />
        </TextContainer>
        {disabledCard && <InpageMessage className="u--marginBottom2" type="info" message="Tertialet er ikke åpent" />}
        <Heading3 className="u--marginBottom1">Rapporter</Heading3>
        <CardContainer>
          <StyledCard
            color={isLoading ? "white" : getColor(rapportStatuser?.Tilskuddsordninger)}
            title="Tilskudd"
            status={isLoading ? <Placeholder /> : getStatusTexts(rapportStatuser?.Tilskuddsordninger)}
            disabled={disabledCard || rapportStatuser?.Tilskuddsordninger === RapportStatus.Irrelevant}
          >
            {(props) =>
              disabledCard || rapportStatuser?.Tilskuddsordninger === RapportStatus.Irrelevant ? (
                <div {...props} />
              ) : (
                <Link {...props} to={`/${routes.tilskuddsordninger(orgnummer, +year, +tertialNr)}`} />
              )
            }
          </StyledCard>
          <StyledCard
            color={isLoading ? "white" : getColor(rapportStatuser?.DriftOgProsjekter)}
            title="Prosjekter"
            status={isLoading ? <Placeholder /> : getStatusTexts(rapportStatuser?.DriftOgProsjekter)}
            disabled={disabledCard || rapportStatuser?.DriftOgProsjekter === RapportStatus.Irrelevant}
          >
            {(props) =>
              disabledCard || rapportStatuser?.DriftOgProsjekter === RapportStatus.Irrelevant ? (
                <div {...props} />
              ) : (
                <Link {...props} to={`/${routes.driftOgProsjekter(orgnummer, +year, +tertialNr)}`} />
              )
            }
          </StyledCard>
          <StyledCard
            color={isLoading ? "white" : getColor(rapportStatuser?.SRapport)}
            title="S-rapport"
            status={isLoading ? <Placeholder /> : getStatusTexts(rapportStatuser?.SRapport)}
            disabled={disabledCard}
          >
            {(props) => (disabledCard ? <div {...props} /> : <Link {...props} to={`/${routes.sRapport(orgnummer, +year, +tertialNr)}`} />)}
          </StyledCard>
        </CardContainer>
        <p className="u--marginTop2">
          Alle deler av rapporten du skal rapportere på må være fylt ut og godkjent før du kan sende inn rapporten.
        </p>
        <div className="u--marginBottom4">
          <Tooltip
            message={
              tertial?.open && disableInnsending
                ? "Alle deler av rapporten du skal rapportere på må være fylt ut og godkjent før du kan sende inn rapporten."
                : undefined
            }
          >
            <Button disabled={!!statsforvalterTertial?.submittedDate || disableInnsending} onClick={showPopin}>
              <Icon icon="email" placement="before" />
              Send inn rapport
            </Button>
          </Tooltip>
          <LinkButton
            disabled={!statsforvalterTertial?.submittedDate}
            href={`/api/download/${year}/${tertialNr}/${orgnummer}`}
            target="_blank"
          >
            <Icon icon="download" placement="before" />
            Last ned rapport
          </LinkButton>
        </div>
      </CenterWrapper>
      <Popin
        id={sendInnRapportConfirmationPopinId}
        title="Er du sikker på at du vil sende inn rapport?"
        onSave={sendInn}
        saveButton="Send inn"
      />
    </MainPanel>
  );
};

