import * as React from "react";
import { Outlet } from "react-router-dom";
import { MainPanel, Page } from "@utdanningsdirektoratet/layout";
import { Notifications } from "@utdanningsdirektoratet/notifications";
import { PopinManager } from "@utdanningsdirektoratet/popin";
import { useContextState } from "./StateProvider";
import ErrorPage from "./pages/ErrorPage";
import { UdirFooter } from "./layout/UdirFooter";
import { Menu } from "./layout/Menu";
import { usePageViewTrackingWithUserInfo } from "./analytics";

const App: React.FC = () => {
  usePageViewTrackingWithUserInfo();
  const [state] = useContextState();

  if (state.error) {
    return <ErrorPage error={state.error} />;
  }

  return (
    <Page>
      <Menu />
      <MainPanel color="white" fullwidth>
        <Outlet />
      </MainPanel>
      <Notifications />
      <PopinManager />
      <UdirFooter />
    </Page>
  );
};

export default App;
