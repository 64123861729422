import { Footer } from "@utdanningsdirektoratet/layout";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { onKeyDown } from "@utdanningsdirektoratet/utils/uu";
import {
  ApplicationInsightsCookieVendor,
  CookieBanner,
  MatomoCookieVendor,
  NecessaryCookie,
  SentryCookieVendor,
  StatisticsCookie,
  UidpCookieVendor,
} from "@utdanningsdirektoratet/cookiebanner";
import routes from "../routes";

export const UdirFooter: React.FC = () => {
  const [cookieState, setCookieState] = React.useState(false);
  const items = [
    <a href={routes.udirOm} target="_blank" rel="noopener noreferrer">
      Om direktoratet
    </a>,
    <a href={routes.udirKontaktinfo} target="_blank" rel="noopener noreferrer">
      Kontaktinformasjon
    </a>,
    <NavLink to={routes.personvern}>Personvernerklæring</NavLink>,
    <a href="https://uustatus.no/nb/erklaringer/publisert/ff9c2e96-a651-49ae-a24f-d24c826c9bc8" target="_blank" rel="noopener noreferrer">
      Tilgjengelighetserklæring
    </a>,
    <div role="link" onClick={() => setCookieState(true)} onKeyDown={onKeyDown(() => setCookieState(true))} tabIndex={0}>
      Informasjonskapsler
    </div>,
  ];

  return (
    <>
      <Footer items={items} udirLogo linkButton={{ href: "udir.no", title: "udir.no" }} />
      <CookieBanner
        show={cookieState}
        necessaryCookie={NecessaryCookie([UidpCookieVendor, SentryCookieVendor])}
        cookies={[StatisticsCookie([ApplicationInsightsCookieVendor, MatomoCookieVendor])]}
        onAccept={() => setCookieState(false)}
      />
    </>
  );
};

