import { usePageViewTracking, userRoleDimension } from "@utdanningsdirektoratet/hooks/usePageViewTracking";
import { getCurrentUser } from "./globalFunctions";
import type { LoggedInPortalUserViewModel, StatsforvalterModel } from "./ApiClients";

const getUserRoleString = (user: LoggedInPortalUserViewModel | undefined): string => {
  if (!user) {
    return "";
  }
  const roles = [];
  if (user.isSaksbehandler) {
    roles.push("Saksbehandler");
  }
  if (user.isStatsforvalter) {
    roles.push("Statsforvalter");
  }
  user.statsforvaltere.forEach((statsforvalter: StatsforvalterModel) => {
    roles.push(statsforvalter.name);
  })
  return roles.join(", ");
}

export const usePageViewTrackingWithUserInfo = () => {
  const userRoleString = getUserRoleString(getCurrentUser());
  usePageViewTracking([userRoleDimension(userRoleString)]);
}
