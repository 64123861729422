import React from "react";
import { Popin, close } from "@utdanningsdirektoratet/popin";
import { Textarea } from "@utdanningsdirektoratet/textarea";

type AddCommentPopinProps = {
  id: string;
  inputValue: string | undefined;
  onSave: (kommentar: string) => void;
};

const AddCommentPopin: React.FC<AddCommentPopinProps> = ({ id, inputValue, onSave }) => {
  const [value, setValue] = React.useState<string>("");

  React.useEffect(() => {
    setValue(inputValue ?? "");
  }, [inputValue]);

  return (
    <Popin
      id={id}
      title={(inputValue ?? "") === "" ? "Skriv en kommentar" : "Rediger kommentar"}
      onSave={() => onSave(value)}
      onCancel={() => {
        setValue(inputValue ?? "");
        close(id);
      }}
    >
      <Textarea label="Kommentar" value={value} onChange={(e) => setValue(e.target.value)} />
    </Popin>
  );
};

export default AddCommentPopin;
