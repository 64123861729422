/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import { KeyedMutator } from "swr";
import { close } from "@utdanningsdirektoratet/popin";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Rapport, RapportClient, RapportType, RegnskapInputModel, Tertial, Tildeling } from "../../ApiClients";
import { CustomTable } from "../../components/CustomTable";
import AddCommentButtonWithPopin from "../../components/AddCommentButtonWithPopin";
import CurrencyInputWrapper from "../../components/CurrencyInputWrapper";
import { formatCurrency, formatOrdning } from "../../utils/formatters";
import { getTertialDeadline } from "../../utils/utils";

type TilskuddsordningTableProps = {
  tildelings: Array<Tildeling>;
  tilskuddsordningRapport: Rapport | undefined;
  mutate: KeyedMutator<Rapport>;
  tertial: Tertial;
  disabled: boolean;
};

const NumberCell = styled.td<{ isNegative?: boolean }>`
  text-align: right;
  ${(p) => (p.isNegative ? "color: var(--alertred)" : "")}
`;

export const TilskuddsordningTable: React.FC<TilskuddsordningTableProps> = ({
  tildelings,
  tilskuddsordningRapport,
  mutate,
  tertial,
  disabled,
}) => {
  const { orgnummer, tertialNr } = useParams() as { orgnummer: string; tertialNr: string };
  const onSaveRegnskap = async (regnskap: RegnskapInputModel, ordningId: number, popinId?: string) => {
    try {
      const client = new RapportClient();
      await client.updateOrCreateRegnskap(ordningId, orgnummer, tertial.year, tertial.tertialNr, RapportType.Tilskuddsordninger, regnskap);
      await mutate();
    } catch (e) {
      /* empty */
    } finally {
      if (popinId) {
        close(popinId);
      }
    }
  };
  const savePropOnRegnskap = async <K extends keyof RegnskapInputModel>(
    regnskap: RegnskapInputModel,
    key: K,
    value: RegnskapInputModel[K],
    ordningId: number,
    popinId?: string
  ) => {
    const updatedRegnskap = { ...regnskap, [key]: value };
    await onSaveRegnskap(updatedRegnskap, ordningId, popinId);
  };

  const getAvvik = (regnskap: RegnskapInputModel | undefined, tildeling: Tildeling) => {
    if (tertial.tertialNr !== 3) {
      return tildeling.hovedtildeling + tildeling.andreBrev - (regnskap?.prognoseBelop ?? 0);
    }

    return tildeling.hovedtildeling + tildeling.andreBrev - (regnskap?.regnskapPrDatoBelop ?? 0);
  };

  const getProsent = (regnskap: RegnskapInputModel | undefined, tildeling: Tildeling) => {
    let prognoseProsent = 0;
    if (tertial.tertialNr !== 3) {
      prognoseProsent = ((regnskap?.prognoseBelop ?? 0) / (tildeling.hovedtildeling + tildeling.andreBrev)) * 100;
    } else {
      prognoseProsent = ((regnskap?.regnskapPrDatoBelop ?? 0) / (tildeling.hovedtildeling + tildeling.andreBrev)) * 100;
    }

    if (Number.isNaN(prognoseProsent) || !Number.isFinite(prognoseProsent)) {
      prognoseProsent = 0;
    }
    return `${prognoseProsent.toFixed(0)}%`;
  };

  return (
    <CustomTable disabled={disabled}>
      <caption>Innrapportering av tilskuddmidler</caption>
      <thead>
        <tr>
          <th>Tilskudd</th>
          <th>Hovedtildeling</th>
          <th>Andre brev</th>
          <th>Sum tildeling</th>
          <th>Regnskap per {getTertialDeadline(+tertialNr)} </th>
          {tertial.tertialNr !== 3 && <th>Prognose i kr</th>}
          <th>{+tertialNr === 3 ? "Forbruk" : "Prognose"} i %</th>
          <th>Avvik {+tertialNr === 3 ? "i forbruk" : "i prognose"}</th>
          <th>Kommentar</th>
        </tr>
      </thead>
      <tbody>
        {tildelings.map((tildeling) => {
          const regnskap = tilskuddsordningRapport?.regnskap?.find((f) => f.tildeling.ordningId === tildeling.ordningId);
          const regnskapFallback: RegnskapInputModel = {
            regnskapPrDatoBelop: 0,
            prognoseBelop: 0,
            comment: "",
          };
          const popinId = `addCommentOnTilskuddsrapport-${tildeling.ordningId}-${tildeling.year}`;

          const prognoseProsent = getProsent(regnskap, tildeling);
          const avvik = getAvvik(regnskap, tildeling);

          return (
            <tr key={tildeling.ordningId}>
              <td>{formatOrdning(tildeling.ordning)}</td>
              <NumberCell>{formatCurrency(tildeling.hovedtildeling)}</NumberCell>
              <NumberCell>{formatCurrency(tildeling.andreBrev)}</NumberCell>
              <NumberCell>{formatCurrency(tildeling.hovedtildeling + tildeling.andreBrev)}</NumberCell>
              <td>
                <CurrencyInputWrapper
                  value={regnskap?.regnskapPrDatoBelop ?? 0}
                  onSave={(belop) => savePropOnRegnskap(regnskap ?? regnskapFallback, "regnskapPrDatoBelop", belop, tildeling.ordningId)}
                  disabled={disabled}
                />
              </td>
              {tertial.tertialNr !== 3 && (
                <td>
                  <CurrencyInputWrapper
                    value={regnskap?.prognoseBelop ?? 0}
                    onSave={(belop) => savePropOnRegnskap(regnskap ?? regnskapFallback, "prognoseBelop", belop, tildeling.ordningId)}
                    disabled={disabled}
                  />
                </td>
              )}
              <NumberCell>{prognoseProsent}</NumberCell>
              <NumberCell isNegative={avvik < 0}>{formatCurrency(avvik)}</NumberCell>
              <td style={{ width: "7rem" }}>
                <AddCommentButtonWithPopin
                  comment={regnskap?.comment ?? ""}
                  disabled={disabled}
                  onSave={(comment) => savePropOnRegnskap(regnskap ?? regnskapFallback, "comment", comment, tildeling.ordningId, popinId)}
                  popinId={popinId}
                />
              </td>
            </tr>
          );
        })}
      </tbody>
    </CustomTable>
  );
};
