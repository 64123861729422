import React, { useEffect } from "react";
import CurrencyInput from "@utdanningsdirektoratet/maskedinput/CurrencyInput";
import { isValidationProblemDetails } from "../utils/utils";

type CurrencyInputWrapperProps = {
  value: number | undefined;
  onSave: (input: number) => Promise<unknown>;
  disabled: boolean;
};

const CurrencyInputWrapper: React.FC<CurrencyInputWrapperProps> = ({ value, onSave, disabled }) => {
  const [input, setInput] = React.useState<string>(value?.toString() ?? "");
  const [error, setError] = React.useState<string | null>(null);

  useEffect(() => setInput(value?.toString() ?? ""), [value]);

  const validateInput = (_input: string) => {
    if (_input.includes(",") || _input.includes(".")) {
      setError("Desimaltall støttes ikke. Tallet må være et heltall");
      return false;
    }

    setError(null);
    return true;
  };

  return (
    <CurrencyInput
      value={input}
      error={error !== null}
      message={error}
      onChange={(e) => {
        validateInput(e);
        setInput(e);
      }}
      disabled={disabled}
      allowNegative
      allowDecimal
      onBlur={async () => {
        if (!input && !value) return;
        if (!validateInput(input)) return;
        try {
          await onSave(+input ?? 0);
        } catch (e) {
          if (isValidationProblemDetails(e)) {
            setError("Verdien er ikke gyldig");
          }
        }
      }}
    />
  );
};

export default CurrencyInputWrapper;
