import React, { useState } from "react";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { close } from "@utdanningsdirektoratet/popin";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import {
  FileParameter,
  RapportClient,
  RapportStatus,
  RapportType,
  StatsforvalterTertialClient,
  TertialClient,
  TextClient,
} from "../../ApiClients";
import { BackNavigation } from "../../components/BackNavigation";
import { GodkjenningForm, SaveGodkjenningType } from "../../components/GodkjenningForm";
import { UploadFiles } from "../../components/UploadFiles";
import routes from "../../routes";
import { HFLSWRStandardOptions, getStatsforvalterTertialKey, getTertialKey } from "../../utils/swrUtils";
import { isProblemDetails } from "../../utils/utils";

const ContentWrapper = styled.div`
  max-width: 30rem;
  margin: 1rem 0;
`;
const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const client = new RapportClient();
const confirmApprovePopinId = "confirm-approve-popin-id";
export const SRapport: React.FC = () => {
  const { orgnummer, year, tertialNr } = useParams() as { orgnummer: string; year: string; tertialNr: string };

  const getSrapportSwrKey = `getSRapport-${orgnummer}-${year}-${tertialNr}`;
  const {
    data: sRapport,
    mutate,
    isLoading,
  } = useSWR(getSrapportSwrKey, () => client.get(orgnummer, +year, +tertialNr, RapportType.SRapport), HFLSWRStandardOptions);
  const { data: tertial } = useSWRImmutable(getTertialKey(year, tertialNr), () => new TertialClient().getTertial(+year, +tertialNr));
  const [fileIsLoading, setFileIsLoading] = React.useState(false);

  const [fileError, setFileError] = useState<boolean>(false);

  const { data: sRapportText } = useSWR("getSRapportText", () => new TextClient().getSRapportText(), HFLSWRStandardOptions);
  const { data: statsforvalterTertial } = useSWR(
    getStatsforvalterTertialKey(orgnummer, year, tertialNr),
    () => new StatsforvalterTertialClient().get(orgnummer, +year, +tertialNr),
    HFLSWRStandardOptions
  );

  const onSaveFiles = async (files: Array<FileParameter>) => {
    try {
      setFileIsLoading(true);
      await client.uploadFiles(orgnummer, +year, +tertialNr, RapportType.SRapport, files);
      await mutate();
    } catch (error) {
      if (!isProblemDetails(error) || error.status !== 422) newNotification("error", "Noe gikk galt ved opplasting av dokument");
    } finally {
      setFileIsLoading(false);
      setFileError(false);
    }
  };

  const onRemoveFiles = async (blobId: string) => {
    setFileIsLoading(true);
    try {
      await client.removeFile(orgnummer, +year, +tertialNr, blobId);
      await mutate();
    } catch {
      newNotification("error", "Noe gikk galt ved sletting av dokument");
    } finally {
      setFileIsLoading(false);
      setFileError(false);
    }
  };

  const approve = async (item: SaveGodkjenningType) => {
    if (sRapport?.files?.length && sRapport?.files?.length > 0) {
      try {
        await client.approveRapport(orgnummer, +year, +tertialNr, RapportType.SRapport, item);
        await mutate();
      } catch {
        /* empty */
      } finally {
        close(confirmApprovePopinId);
      }
    } else {
      setFileError(true);
    }
  };

  const saveContactInformation = async (item: SaveGodkjenningType) => {
    await client.updateContactInformation(orgnummer, +year, +tertialNr, RapportType.SRapport, item);
    await mutate();
  };

  const disabled =
    !tertial?.open ||
    sRapport?.status === RapportStatus.Approved ||
    sRapport?.status === RapportStatus.Closed ||
    !!statsforvalterTertial?.submittedDate;

  return (
    <MainPanel color="white">
      <BoxWrapper>
        <BackNavigation to={`/${routes.home(orgnummer, year, tertialNr)}`} />
        <Heading2>S-rapport</Heading2>
        <ContentWrapper>
          {!isLoading && (sRapport?.files?.length ?? 0) < 1 && <div className="u--marginBottom1">Ingen filer lastet opp.</div>}
          <UploadFiles
            rapport={
              sRapport ?? {
                statsforvalterOrgnummer: orgnummer,
                status: RapportStatus.NotReported,
                files: [],
                tertialNr: +tertialNr,
                year: +year,
                type: RapportType.SRapport,
              }
            }
            onSave={onSaveFiles}
            onRemove={onRemoveFiles}
            isPending={fileIsLoading}
            disabled={disabled}
            hasError={fileError}
          />
        </ContentWrapper>
      </BoxWrapper>
      <GodkjenningForm
        input={sRapport}
        onSave={approve}
        onSaveContactInformation={saveContactInformation}
        popinTitle="Godkjenn S-rapport"
        popinContent={
          <>
            <div>Er du sikker på at du ønsker å godkjenne? </div>
            <div>Etter godkjenning vil alle felter på siden bli låst for redigering.</div>
          </>
        }
        rapportText={sRapportText}
        disabled={disabled}
      />
    </MainPanel>
  );
};
