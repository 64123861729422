import { MainPanel } from "@utdanningsdirektoratet/layout";
import { close } from "@utdanningsdirektoratet/popin";
import { Heading2 } from "@utdanningsdirektoratet/styles";
import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { RapportClient, RapportStatus, RapportType, TertialClient, TextClient, TildelingsClient } from "../../ApiClients";
import { BackNavigation } from "../../components/BackNavigation";
import { BoxWrapper } from "../../components/BoxWrapper";
import { GodkjenningForm, SaveGodkjenningType } from "../../components/GodkjenningForm";
import { LastSaved } from "../../components/LastSaved";
import routes from "../../routes";
import { HFLSWRStandardOptions, getTertialKey } from "../../utils/swrUtils";
import { FileWrapper } from "./FileWrapper";
import { TilskuddsordningTable } from "./TilskuddsordningTable";

const confirmApprovePopinId = "confirm-approve-popin-id";
const client = new RapportClient();

export const TilskuddsordningRapport: React.FC = () => {
  const { orgnummer, year, tertialNr } = useParams() as { orgnummer: string; year: string; tertialNr: string };
  const { data: tertial } = useSWRImmutable(getTertialKey(year, tertialNr), () => new TertialClient().getTertial(+year, +tertialNr));

  const getTilskuddsordningRapportKey = `getTilskuddsordningRapport-${year}-${tertialNr}`;
  const getTildelingsKey = `getTildelings-${year}-${orgnummer}`;
  const { data: tilskuddsordningRapport, mutate } = useSWR(
    getTilskuddsordningRapportKey,
    () => client.get(orgnummer, +year, +tertialNr, RapportType.Tilskuddsordninger),
    HFLSWRStandardOptions
  );
  const { data: tildelings } = useSWRImmutable(getTildelingsKey, () => new TildelingsClient().getTildelingsForOrdninger(orgnummer, +year));

  const { data: tilskuddsordingText } = useSWRImmutable("getTilskuddsordningText", () => new TextClient().getTilskuddsordningText());
  const approveRapport = async (godkjenning: SaveGodkjenningType) => {
    try {
      await client.approveRapport(orgnummer, +year, +tertialNr, RapportType.Tilskuddsordninger, godkjenning);
      await mutate();
    } catch {
      /* empty */
    } finally {
      close(confirmApprovePopinId);
    }
  };

  const saveContactInformation = async (item: SaveGodkjenningType) => {
    await client.updateContactInformation(orgnummer, +year, +tertialNr, RapportType.Tilskuddsordninger, item);
    await mutate();
  };

  if (!tertial) return null;

  const disabledForm =
    !tertial?.open ||
    tilskuddsordningRapport?.status === RapportStatus.Approved ||
    tilskuddsordningRapport?.status === RapportStatus.Closed;

  return (
    <MainPanel color="white">
      <BoxWrapper>
        <BackNavigation to={`/${routes.home(orgnummer, year, tertialNr)}`} />
        <Heading2>Tilskudd</Heading2>
        {tildelings && tertial && (
          <TilskuddsordningTable
            tildelings={tildelings}
            tilskuddsordningRapport={tilskuddsordningRapport}
            mutate={mutate}
            tertial={tertial}
            disabled={disabledForm}
          />
        )}
      </BoxWrapper>
      <FileWrapper
        tilskuddsordningRapport={tilskuddsordningRapport}
        orgnummer={orgnummer}
        tertial={tertial}
        mutate={mutate}
        disabled={disabledForm}
      />
      <LastSaved date={tilskuddsordningRapport?.lastEditedDate} />
      <GodkjenningForm
        input={tilskuddsordningRapport}
        rapportText={tilskuddsordingText}
        onSaveContactInformation={saveContactInformation}
        onSave={approveRapport}
        popinTitle="Godkjenn rapport for tilskudd"
        popinContent={
          <div>Er du sikker på at du ønsker å godkjenne? Etter godkjenning vil alle felter på siden bli låst for redigering.</div>
        }
        disabled={disabledForm}
      />
    </MainPanel>
  );
};
