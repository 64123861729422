import { MainPanel } from "@utdanningsdirektoratet/layout";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { close } from "@utdanningsdirektoratet/popin";
import { Heading2, Heading3 } from "@utdanningsdirektoratet/styles";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import useSwr from "swr";
import useSWRImmutable from "swr/immutable";
import { FileParameter, RapportClient, RapportStatus, RapportType, TertialClient, TextClient, TildelingsClient } from "../../ApiClients";
import { BackNavigation } from "../../components/BackNavigation";
import { GodkjenningForm, SaveGodkjenningType } from "../../components/GodkjenningForm";
import { LastSaved } from "../../components/LastSaved";
import { UploadFiles } from "../../components/UploadFiles";
import routes from "../../routes";
import { HFLSWRStandardOptions, getTertialKey } from "../../utils/swrUtils";
import { DriftOgProsjekterTable } from "./DriftOgProsjekterTable";
import { isProblemDetails } from "../../utils/utils";

const ContentWrapper = styled.div`
  max-width: 30rem;
  margin: 1rem 0;
`;
const BoxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const client = new RapportClient();
const tildelingsClient = new TildelingsClient();
const confirmApprovePopinId = "confirm-approve-popin-id";

export const DriftOgProsjekter: React.FC = () => {
  const { orgnummer, year, tertialNr } = useParams() as { orgnummer: string; year: string; tertialNr: string };
  const getDriftOgProsjektrapportSwrKey = `getDriftOgProsjektrapport-${orgnummer}-${+year}-${+tertialNr}`;
  const getTildelingerSwrKey = `getTildelinger-${orgnummer}-${+year}`;
  const {
    data: driftOgProsjektRapport,
    mutate,
    isLoading,
  } = useSwr(
    getDriftOgProsjektrapportSwrKey,
    () => client.get(orgnummer, +year, +tertialNr, RapportType.DriftOgProsjekter),
    HFLSWRStandardOptions
  );
  const { data: tildelings } = useSwr(
    getTildelingerSwrKey,
    () => tildelingsClient.getTildelingsForProsjekter(orgnummer, +year),
    HFLSWRStandardOptions
  );

  const { data: tertial } = useSWRImmutable(getTertialKey(year, tertialNr), () => new TertialClient().getTertial(+year, +tertialNr));
  const [fileIsLoading, setFileIsLoading] = React.useState(false);

  const { data: driftOgProsjekterText } = useSwr(
    "getdriftOgProsjekterText",
    () => new TextClient().getDriftOgProsjekterText(),
    HFLSWRStandardOptions
  );

  const onSaveFiles = async (files: Array<FileParameter>) => {
    try {
      setFileIsLoading(true);
      await client.uploadFiles(orgnummer, +year, +tertialNr, RapportType.DriftOgProsjekter, files);
      await mutate();
    } catch (error) {
      if (!isProblemDetails(error) || error.status !== 422) newNotification("error", "Noe gikk galt ved opplasting av dokument");
    } finally {
      setFileIsLoading(false);
    }
  };

  const onRemoveFiles = async (blobId: string) => {
    setFileIsLoading(true);
    try {
      await client.removeFile(orgnummer, +year, +tertialNr, blobId);
      await mutate();
    } catch {
      newNotification("error", "Noe gikk galt ved sletting av dokument");
    } finally {
      setFileIsLoading(false);
    }
  };

  const approve = async (item: SaveGodkjenningType) => {
    try {
      await client.approveRapport(orgnummer, +year, +tertialNr, RapportType.DriftOgProsjekter, item);
      await mutate();
    } catch {
      /* empty */
    } finally {
      close(confirmApprovePopinId);
    }
  };

  const saveContactInformation = async (item: SaveGodkjenningType) => {
    await client.updateContactInformation(orgnummer, +year, +tertialNr, RapportType.DriftOgProsjekter, item);
    await mutate();
  };

  const disabledForm =
    !tertial?.open || driftOgProsjektRapport?.status === RapportStatus.Approved || driftOgProsjektRapport?.status === RapportStatus.Closed;

  return (
    <MainPanel color="white">
      <BoxWrapper>
        <BackNavigation to={`/${routes.home(orgnummer, year, tertialNr)}`} />
        <Heading2>Prosjekter</Heading2>
        <DriftOgProsjekterTable rapport={driftOgProsjektRapport} mutate={mutate} tildelings={tildelings} disabled={disabledForm} />
        <ContentWrapper>
          <Heading3 className="u--marginBottom1">Vedlegg</Heading3>
          {!isLoading && (driftOgProsjektRapport?.files?.length ?? 0) < 1 && (
            <div className="u--marginBottom1">Ingen filer lastet opp.</div>
          )}
          <UploadFiles
            rapport={
              driftOgProsjektRapport ?? {
                statsforvalterOrgnummer: orgnummer,
                status: RapportStatus.NotReported,
                files: [],
                tertialNr: +tertialNr,
                year: +year,
                type: RapportType.DriftOgProsjekter,
              }
            }
            onSave={onSaveFiles}
            onRemove={onRemoveFiles}
            isPending={fileIsLoading}
            disabled={!tertial?.open}
          />
        </ContentWrapper>
      </BoxWrapper>
      <LastSaved date={driftOgProsjektRapport?.lastEditedDate} />
      <GodkjenningForm
        input={driftOgProsjektRapport}
        onSave={approve}
        onSaveContactInformation={saveContactInformation}
        popinTitle="Godkjenning"
        popinContent={
          <>
            <div>Er du sikker på at du ønsker å godkjenne? </div>
            <div>Etter godkjenning vil alle felter på siden bli låst for redigering.</div>
          </>
        }
        rapportText={driftOgProsjekterText}
        disabled={disabledForm}
      />
    </MainPanel>
  );
};
