import React from "react";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import { KeyedMutator } from "swr";
import { Heading3 } from "@utdanningsdirektoratet/styles";
import styled from "styled-components";
import { UploadFiles } from "../../components/UploadFiles";
import { FileParameter, Rapport, RapportStatus, RapportType, Tertial, RapportClient } from "../../ApiClients";
import { isProblemDetails } from "../../utils/utils";

type FileWrapperProps = {
  tilskuddsordningRapport: Rapport | undefined;
  orgnummer: string;
  tertial: Tertial;
  mutate: KeyedMutator<Rapport>;
  disabled?: boolean;
};

const Wrapper = styled.div`
  max-width: 30rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
`;

const client = new RapportClient();

export const FileWrapper: React.FC<FileWrapperProps> = ({ tertial, tilskuddsordningRapport, mutate, orgnummer, disabled }) => {
  const [fileIsLoading, setFileIsLoading] = React.useState(false);
  const onSaveFiles = async (files: Array<FileParameter>) => {
    try {
      setFileIsLoading(true);
      await client.uploadFiles(orgnummer, tertial.year, tertial.tertialNr, RapportType.Tilskuddsordninger, files);
      await mutate();
    } catch (error) {
      if (!isProblemDetails(error) || error.status !== 422) newNotification("error", "Noe gikk galt ved opplasting av dokument");
    } finally {
      setFileIsLoading(false);
    }
  };

  const onRemoveFiles = async (blobId: string) => {
    setFileIsLoading(true);
    try {
      await client.removeFile(orgnummer, tertial.year, tertial.tertialNr, blobId);
      await mutate();
    } catch {
      newNotification("error", "Noe gikk galt ved sletting av dokument");
    } finally {
      setFileIsLoading(false);
    }
  };

  return (
    <Wrapper>
      <Heading3>Vedlegg</Heading3>
      <UploadFiles
        rapport={
          tilskuddsordningRapport ?? {
            statsforvalterOrgnummer: orgnummer,
            status: RapportStatus.NotReported,
            files: [],
            tertialNr: tertial.tertialNr,
            year: tertial.year,
            type: RapportType.Tilskuddsordninger,
            lastEditedBy: "",
            lastEditedDate: new Date(),
          }
        }
        onSave={onSaveFiles}
        onRemove={onRemoveFiles}
        isPending={fileIsLoading}
        disabled={disabled || tilskuddsordningRapport?.status === RapportStatus.Approved}
      />
    </Wrapper>
  );
};
