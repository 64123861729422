import * as React from 'react';
import { MenuPanel } from '@utdanningsdirektoratet/layout';
import { getCurrentUser } from '../globalFunctions';

export const Menu: React.FC = () => {
  const user = getCurrentUser();
  return (
    <MenuPanel title="Tertialportalen" external>
      {user?.fullName}
    </MenuPanel>
  );
};
