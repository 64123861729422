import { Heading1 } from "@utdanningsdirektoratet/styles";
import React from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import useSWRImmutable from "swr/immutable";
import { YearPicker } from "@utdanningsdirektoratet/datepicker";
import routes from "../routes";
import { StatsforvalterClient } from "../ApiClients";
import TertialTabs from "./TertialTabs";
import { GetStatsforvaltereKey } from "../utils/swrUtils";

const StatsforvalterBox = styled.div`
  padding: 3rem 2rem;
  background-color: var(--steelblue4);
  border-bottom: 1px solid val(--steelblue5);
`;

const StyledYearPicker = styled(YearPicker)`
  > label {
    width: 100px;
  }
  > div {
    width: 200px;
  }
`;

export const StatsforvalterHeader: React.FC = () => {
  const { orgnummer, year } = useParams() as { orgnummer: string; year: string };
  const navigate = useNavigate();
  const location = useLocation();

  const { data: statsforvaltere } = useSWRImmutable(GetStatsforvaltereKey(), () =>
    new StatsforvalterClient().getStatsforvaltere(new Date())
  );

  const name = statsforvaltere?.find((s) => s.orgNr === orgnummer)?.name;

  // Sjekker om vi har et organisasjonsnummer i url'n. Hvis ikke
  // redirect til valg av statsforvalter.
  React.useEffect(() => {
    const pattern = /^\d{9}(\/|$)/;
    if (pattern.test(orgnummer) !== true) {
      navigate(`/${routes.chooseStatsforvalter}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgnummer]);

  const onYearChange = (date: Date | undefined) => {
    if (!date) return;
    const currentLocation = location.pathname;
    const newUrl = currentLocation.replace(/\/(19|20)\d{2}/, `/${date.getFullYear()}`);
    navigate(newUrl);
  };

  return (
    <>
      <StatsforvalterBox>
        <Heading1>{name}</Heading1>
        {year && <StyledYearPicker value={new Date(+year, 1, 1)} onChange={onYearChange} label="Velg år" className="u--marginTop1" />}
      </StatsforvalterBox>
      <TertialTabs />
      <Outlet />
    </>
  );
};

