/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import styled from "styled-components";
import { KeyedMutator } from "swr";
import { useParams } from "react-router-dom";
import { close } from "@utdanningsdirektoratet/popin";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { Rapport, RapportType, RegnskapInputModel, Tildeling, RapportClient } from "../../ApiClients";
import { CustomTable } from "../../components/CustomTable";
import { formatCurrency, formatOrdning } from "../../utils/formatters";
import CurrencyInputWrapper from "../../components/CurrencyInputWrapper";
import { getTertialDeadline } from "../../utils/utils";
import AddCommentButtonWithPopin from "../../components/AddCommentButtonWithPopin";

const NumberCell = styled.td<{ isNegative?: boolean }>`
  text-align: right;
  ${(p) => (p.isNegative ? "color: var(--alertred)" : "")}
`;

type DriftOgProsjekterTableProps = {
  rapport: Rapport | undefined;
  mutate: KeyedMutator<Rapport>;
  tildelings: Array<Tildeling> | undefined;
  disabled: boolean;
};

const client = new RapportClient();

export const DriftOgProsjekterTable: React.FC<DriftOgProsjekterTableProps> = ({ rapport, mutate, tildelings, disabled }) => {
  const { orgnummer, year, tertialNr } = useParams() as { orgnummer: string; year: string; tertialNr: string };
  const updateRegnskap = async (ordningId: number, regnskapInput: RegnskapInputModel, popinId?: string) => {
    await client.updateOrCreateRegnskap(ordningId, orgnummer, +year, +tertialNr, RapportType.DriftOgProsjekter, regnskapInput);
    await mutate();
    if (popinId) {
      close(popinId);
    }
  };

  const getAvvik = (regnskap: RegnskapInputModel | undefined, tildeling: Tildeling) => {
    if (+tertialNr !== 3) {
      return tildeling.hovedtildeling + tildeling.andreBrev - (regnskap?.prognoseBelop ?? 0);
    }

    return tildeling.hovedtildeling + tildeling.andreBrev - (regnskap?.regnskapPrDatoBelop ?? 0);
  };

  const savePropOnRegnskap = async <K extends keyof RegnskapInputModel>(
    regnskap: RegnskapInputModel,
    key: K,
    value: RegnskapInputModel[K],
    ordningId: number,
    popinId?: string
  ) => {
    const updatedRegnskap = { ...regnskap, [key]: value };
    await updateRegnskap(ordningId, updatedRegnskap, popinId);
  };

  if (!tildelings) {
    return null;
  }

  return (
    <CustomTable disabled={disabled}>
      <caption>Innrapportering av prosjektmidler</caption>
      <thead>
        <tr>
          <th>Prosjekt</th>
          <th>Hovedtildeling</th>
          <th>Andre brev</th>
          <th>Sum tildeling</th>
          <th>Regnskap per {getTertialDeadline(+tertialNr)}</th>
          {+tertialNr !== 3 && <th>Prognose i kr</th>}
          <th>{+tertialNr === 3 ? "Forbruk" : "Prognose"} i %</th>
          <th>Avvik {+tertialNr === 3 ? "i forbruk" : "i prognose"}</th>
          {+tertialNr === 3 && <th>Søkt overføring</th>}
          <th>Kommentar</th>
        </tr>
      </thead>
      <tbody>
        {tildelings.map((tildeling) => {
          const regnskap: RegnskapInputModel | undefined = rapport?.regnskap?.find((f) => f.tildeling.ordningId === tildeling.ordningId);
          const regnskapFallback: RegnskapInputModel = {
            regnskapPrDatoBelop: 0,
            prognoseBelop: 0,
            comment: "",
          };
          const popinId = `addCommentOnDriftOgProsjektrapport-${tildeling.ordningId}-${tildeling.year}`;
          const regnskapEllerPrognoseBelop = +tertialNr === 3 ? regnskap?.regnskapPrDatoBelop : regnskap?.prognoseBelop;
          let forbrukProsent = ((regnskapEllerPrognoseBelop ?? 0) / (tildeling.hovedtildeling + tildeling.andreBrev)) * 100;
          if (Number.isNaN(forbrukProsent) || !Number.isFinite(forbrukProsent)) {
            forbrukProsent = 0;
          }
          const forbrukProsentFormatted = `${forbrukProsent.toFixed(0)}%`;
          const avvik = getAvvik(regnskap, tildeling);
          return (
            <tr key={tildeling.ordningId}>
              <td>{formatOrdning(tildeling.ordning)}</td>
              <NumberCell>{formatCurrency(tildeling.hovedtildeling)}</NumberCell>
              <NumberCell>{formatCurrency(tildeling.andreBrev)}</NumberCell>
              <NumberCell>{formatCurrency(tildeling.hovedtildeling + tildeling.andreBrev)}</NumberCell>
              <td style={{ width: "16rem" }}>
                <CurrencyInputWrapper
                  value={regnskap?.regnskapPrDatoBelop ?? 0}
                  onSave={(input) =>
                    savePropOnRegnskap(regnskap ?? regnskapFallback, "regnskapPrDatoBelop", Number(input), tildeling.ordningId)
                  }
                  disabled={disabled}
                />
              </td>
              {+tertialNr !== 3 && (
                <td>
                  <CurrencyInputWrapper
                    value={regnskap?.prognoseBelop ?? 0}
                    onSave={(belop) => savePropOnRegnskap(regnskap ?? regnskapFallback, "prognoseBelop", belop, tildeling.ordningId)}
                    disabled={disabled}
                  />
                </td>
              )}
              <NumberCell>{forbrukProsentFormatted}</NumberCell>

              <NumberCell isNegative={avvik < 0}>{formatCurrency(avvik)}</NumberCell>
              {+tertialNr === 3 && (
                <td style={{ width: "8rem" }}>
                  <CurrencyInputWrapper
                    value={regnskap?.soktOverforing ?? undefined}
                    onSave={(input) =>
                      savePropOnRegnskap(regnskap ?? regnskapFallback, "soktOverforing", Number(input), tildeling.ordningId)
                    }
                    disabled={disabled}
                  />
                </td>
              )}
              <td style={{ width: "7rem" }}>
                <Tooltip
                  disabled={!regnskap?.soktOverforing || regnskap?.soktOverforing === 0 || (regnskap?.comment ?? "") !== ""}
                  message="Må fylles ut"
                  alwaysShow={(regnskap?.soktOverforing ?? 0) !== 0 && (regnskap?.comment ?? "") === ""}
                >
                  <AddCommentButtonWithPopin
                    comment={regnskap?.comment ?? ""}
                    disabled={disabled}
                    onSave={(comment) => savePropOnRegnskap(regnskap ?? regnskapFallback, "comment", comment, tildeling.ordningId, popinId)}
                    popinId={popinId}
                  />
                </Tooltip>
              </td>
            </tr>
          );
        })}
      </tbody>
    </CustomTable>
  );
};
