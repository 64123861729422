import { FileInput } from "@utdanningsdirektoratet/fileinput";
import { Icon } from "@utdanningsdirektoratet/icon";
import { newNotification } from "@utdanningsdirektoratet/notifications";
import React from "react";
import { Vedlegg } from "@utdanningsdirektoratet/vedlegg";
import styled from "styled-components";
import { RapportStatus, Rapport, FileReference, FileParameter } from "../ApiClients";

type GodkjenningType = Partial<Rapport> & Pick<Rapport, "status">;

type BudsjettInputProps = {
  rapport: GodkjenningType & { files: Array<FileReference> };
  onSave: (files: Array<FileParameter>) => void;
  onRemove: (blobId: string) => void;
  isPending?: boolean;
  disabled?: boolean;
  hasError?: boolean;
};

const VedleggWrapper = styled.div`
  margin-top: 1rem;
`;

export const allowedDocumentTypes = [
  "application/x-compressed",
  "text/plain",
  "text/xml",
  "application/pdf",
  "image/jpeg",
  "text/richtext",
  "application/msword",
  "application/mspowerpoint",
  "application/vnd.ms-excel",
  "image/svg+xml",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.oasis.opendocument.spreadsheet",
];

const validateDocuments = (documents: Array<File>) => {
  const errorMessages = documents.map((file) => {
    if (!allowedDocumentTypes!.includes(file.type)) {
      return `Filtypen på filen ${file.name} støttes dessverre ikke. Prøv å konverter den til et annet format.`;
    }
    if (file.size > 10000000) {
      return `Filstørrelsen på dokumentet "${file.name}" er for stor. Filstørrelsen må være under 10MB.`;
    }
    if (file.name.length > 255) {
      return "Dokumentet du prøvde å laste opp har for langt filnavn. Filnavnet må være under 255 karakterer.";
    }
    return null;
  });
  return errorMessages;
};

export const UploadFiles: React.FC<BudsjettInputProps> = ({ rapport, onSave, isPending, onRemove, disabled, hasError }) => {
  const icon = isPending ? "spinner" : "plus";

  const onUpload = async (newFiles: Array<File>) => {
    const errorMessages = validateDocuments(newFiles);
    if (errorMessages.filter((x) => x != null).length > 0) {
      errorMessages.forEach((error) => {
        newNotification("error", "Opplasting feilet", `${error}`);
      });
      return;
    }

    onSave(newFiles.map((file) => ({ data: file, fileName: file.name })));
  };

  return (
    <>
      <FileInput
        multiple
        disabled={disabled || rapport.status === RapportStatus.Approved}
        label={
          <span>
            <Icon icon={icon} placement="before" /> Last opp dokument
          </span>
        }
        onChange={onUpload}
        accept={allowedDocumentTypes.join(", ")}
        error={hasError}
        message="Må lastes opp"
      />
      <VedleggWrapper>
        {rapport.files.map((vedlegg, index) => {
          return (
            <Vedlegg
              key={index + vedlegg.fileName}
              title={vedlegg.fileName}
              href={`/api/rapport/attachment/${vedlegg.id}`}
              onDelete={() => onRemove(vedlegg.blobId)}
              disabled={disabled || rapport.status === RapportStatus.Approved}
            />
          );
        })}
      </VedleggWrapper>
    </>
  );
};
