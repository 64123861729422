import { Button } from "@utdanningsdirektoratet/button";
import { Form, useFormValidation, validationFunctions } from "@utdanningsdirektoratet/form";
import { Popin, show, close } from "@utdanningsdirektoratet/popin";
import { Heading3 } from "@utdanningsdirektoratet/styles";
import { ValidationType } from "@utdanningsdirektoratet/form/types";
import { Input } from "@utdanningsdirektoratet/input";
import { RichTextView } from "@utdanningsdirektoratet/richtext";
import { formatDate } from "@utdanningsdirektoratet/utils/date";
import React from "react";
import styled from "styled-components";
import { Rapport, RapportStatus, Text } from "../ApiClients";
import { BoxWrapper } from "./BoxWrapper";

export type SaveGodkjenningType = { name: string; phonenumber: string; email: string };

type GodkjenningFormProps = {
  input: Rapport | undefined;
  onSave: (item: SaveGodkjenningType) => void;
  popinTitle: string;
  popinContent: React.ReactNode;
  rapportText: Text | undefined;
  disabled?: boolean;
  onSaveContactInformation: (item: SaveGodkjenningType) => void;
};

type FormValues = { name: string; email: string; phonenumber: string };

const InputWrapper = styled.div`
  max-width: 20rem;
  margin-bottom: 1rem;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;

const phonenumberRequired: ValidationType<Partial<FormValues>, string | undefined> = {
  validation: (value) => !!value && /^\+?(?:[0-9] ?){0,4}[0-9]{8,12}$/.test(`${value}`.replace(/[\s-]+/gi, "")),
  message: "Ugyldig telefonnummer",
};

const confirmApprovePopinId = "confirm-approve-popin-id";

export const GodkjenningForm: React.FC<GodkjenningFormProps> = ({
  input,
  onSave,
  popinTitle,
  popinContent,
  rapportText,
  disabled,
  onSaveContactInformation,
}) => {
  const formValidation = useFormValidation<FormValues>({
    name: {
      value: input?.name ?? "",
      validation: [validationFunctions.required],
    },
    email: {
      value: input?.email ?? "",
      validation: [validationFunctions.required, validationFunctions.email],
    },
    phonenumber: {
      value: input?.phonenumber ?? "",
      validation: [validationFunctions.required, phonenumberRequired],
    },
  });

  const { fields } = formValidation;

  const approve = async () => {
    onSave({ name: fields.name.value, email: fields.email.value, phonenumber: fields.phonenumber.value });
  };

  const saveContactInformation = async () => {
    onSaveContactInformation({ name: fields.name.value, email: fields.email.value, phonenumber: fields.phonenumber.value });
  };

  return (
    <>
      <Form {...formValidation} onSubmit={() => show(confirmApprovePopinId)} unsavedChanges={false}>
        {({ onSubmit }) => (
          <div className="u--marginBottom4">
            <BoxWrapper>
              <Heading3>Kontaktinformasjon</Heading3>
              <InputWrapper>
                <Input
                  label="Navn"
                  {...fields.name}
                  disabled={disabled || input?.status === RapportStatus.Approved}
                  onBlur={saveContactInformation}
                  type="text"
                  autoComplete="name"
                  className="u--marginBottom1"
                />
                <Input
                  label="E-post"
                  {...fields.email}
                  disabled={disabled || input?.status === RapportStatus.Approved}
                  onBlur={saveContactInformation}
                  autoComplete="email"
                  type="email"
                  className="u--marginBottom1"
                />
                <Input
                  label="Telefonnummer"
                  {...fields.phonenumber}
                  onBlur={saveContactInformation}
                  autoComplete="tel"
                  type="tel"
                  disabled={disabled || input?.status === RapportStatus.Approved}
                />
              </InputWrapper>
              <RichTextView html={rapportText?.content ?? ""} />
            </BoxWrapper>
            <ButtonRow>
              <Button disabled={input?.status === RapportStatus.Approved || disabled} onClick={onSubmit} color="green">
                Godkjenn
              </Button>
              {input?.status === RapportStatus.Approved && <div>Godkjent: {formatDate(input.approvedDate!, "dd.MM.yyyy")} </div>}
            </ButtonRow>
          </div>
        )}
      </Form>
      <Popin
        id={confirmApprovePopinId}
        title={popinTitle}
        onSave={approve}
        onCancel={() => close(confirmApprovePopinId)}
        saveButton="Godkjenn"
      >
        {popinContent}
      </Popin>
    </>
  );
};
