import { Ordning } from "../ApiClients";

export const formatCurrency = (belop: number, fractionDigits = 0) => {
  return new Intl.NumberFormat("nb-NO", {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(belop);
};

export const formatOrdning = (ordning: Ordning, includeTitle = true) => {
  const sequence = `${Number(ordning.kapittel).toString().padStart(4, "0")}.${Number(ordning.post)
    .toString()
    .padStart(2, "0")}.${Number(ordning.prosjekt ?? 0)
    .toString()
    .padStart(5, "0")}`;

  if (includeTitle) {
    return `${sequence} ${ordning.title}`;
  }
  return sequence;
};
