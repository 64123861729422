import { InpageMessage } from "@utdanningsdirektoratet/inpagemessage";
import { Heading1 } from "@utdanningsdirektoratet/styles";
import useSWRImmutable from "swr/immutable";
import { Table, Column } from "@utdanningsdirektoratet/table";
import { MainPanel } from "@utdanningsdirektoratet/layout";
import { useNavigate } from "react-router-dom";
import { StatsforvalterClient, TertialClient } from "../ApiClients";
import { getCurrentUser } from "../globalFunctions";
import { GetStatsforvaltereKey } from "../utils/swrUtils";
import { statsforvalterByFylkeSort } from "../utils/utils";

interface TableModel {
  id: string;
  name: string;
}

const columns: Array<Column<TableModel>> = [
  {
    key: "id",
    header: "Organisasjonsnummer",
    tooltip: false,
  },
  {
    key: "name",
    header: "Navn",
    tooltip: false,
  },
];

const sortByFylke = (a: TableModel, b: TableModel) => {
  return statsforvalterByFylkeSort(a.name, b.name);
};

export const SelectorStatsforvalter: React.FC = () => {
  const user = getCurrentUser();
  const navigate = useNavigate();
  const { data: statsforvaltere } = useSWRImmutable(GetStatsforvaltereKey(), () =>
    new StatsforvalterClient().getStatsforvaltere(new Date())
  );

  const selectStatsforvalter = async (orgNr: string) => {
    const client = new TertialClient();
    const tertial = await client.getCurrentTertial();
    navigate(`/${orgNr}/${tertial.year}/${tertial.tertialNr}`);
  };

  const data = statsforvaltere
    ?.filter((f) => {
      if (user?.isSaksbehandler === true) {
        return true;
      }
      return user?.statsforvaltere.some((s) => s.orgNr === f.orgNr) ?? false;
    })
    .map((s) => ({ id: s.orgNr, name: s.name } as TableModel));

  if (data?.length === 1) {
    selectStatsforvalter(data[0].id);
  }

  return (
    <MainPanel color="white">
      {data?.length === 0 && (
        <InpageMessage
          type="info"
          message={
            <span>
              Vi kunne ikke finne noen virksomheter som du er tilknyttet i Altinn som har tilgang til denne løsningen.
              For å få tilgang til Tertialportalen, må du være tilknyttet Statsforvalteren du ønsker å rapportere på
              vegne av i Altinn. Du kan lese mer om tilgangsstyring i Altinn{" "}
              <a target="_blank" href="https://uidp-admin.udir.no/info/altinn" rel="noreferrer">
                her
              </a>
              .
            </span>
          }
        />
      )}
      {data && data.length > 1 && (
        <>
          <Heading1 className="u--marginBottom2 u--marginTop1">
            Hvilken virksomhet ønsker du å logge inn på vegne av?
          </Heading1>
          <Table<TableModel>
            columns={columns}
            data={(data ?? []).sort(sortByFylke)}
            options={{ rowOnClick: (row) => selectStatsforvalter(row.id) }}
          />
        </>
      )}
    </MainPanel>
  );
};

