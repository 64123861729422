import React from "react";
import { Tooltip } from "@utdanningsdirektoratet/tooltip";
import { IconButton } from "@utdanningsdirektoratet/button";
import { show } from "@utdanningsdirektoratet/popin";
import { Icon } from "@utdanningsdirektoratet/icon";
import AddCommentPopin from "./AddCommentPopin";

type AddCommentButtonWithPopinProps = {
  comment: string | undefined;
  disabled: boolean;
  onSave: (comment: string) => void;
  popinId: string;
};

const AddCommentButtonWithPopin: React.FC<AddCommentButtonWithPopinProps> = ({ comment, disabled, onSave, popinId }) => {
  return (
    <>
      {comment ? (
        <Tooltip message={comment} position="left">
          <IconButton onClick={() => show(popinId)} disabled={disabled}>
            <Icon icon="speech bubble filled" />
          </IconButton>
        </Tooltip>
      ) : (
        <IconButton onClick={() => show(popinId)} disabled={disabled}>
          <Icon icon="speech bubble" />
        </IconButton>
      )}
      <AddCommentPopin id={popinId} inputValue={comment} onSave={onSave} />
    </>
  );
};

export default AddCommentButtonWithPopin;

