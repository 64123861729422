import * as React from 'react';
import { ApiException } from '../ApiClients';

export const ERROR_SET = 'SET_ERROR';
export const initialState: ReducerStateProps = { error: null };

export type ReducerStateProps = {
  error: ApiException | null;
}

type SetErrorType = {
  type: typeof ERROR_SET;
  payload: ApiException;
}

export type ActionTypeProps = SetErrorType;

const reducer: React.Reducer<ReducerStateProps, ActionTypeProps> = (state = initialState, action) => {
  switch (action.type) {
    case ERROR_SET:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
