import { useEffect } from "react";
import "./index.less";
import { createRoot } from "react-dom/client";
import {
  RouterProvider,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  createBrowserRouter,
  matchRoutes,
  Navigate,
  Outlet,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import App from "./App";
import StateProvider from "./StateProvider";
import reducer, { initialState } from "./reducers";
import { getCurrentUser, getSentryId } from "./globalFunctions";
import { SRapport } from "./pages/Srapport/SRapport";
import { SelectorStatsforvalter } from "./pages/SelectStatsforvalter";
import { StatsforvalterHeader } from "./layout/StatsforvalterHeader";
import { Forside } from "./pages/Forside";
import { TilskuddsordningRapport } from "./pages/TilskuddsordningRapport/TilskuddsordningRapport";
import routes from "./routes";
import { DriftOgProsjekter } from "./pages/DriftOgProsjekter/DriftOgProsjekter";
import { Personvern } from "./pages/Personvern/Personvern";
import { LogonPage } from "./pages/LogonPage/LogonPage";

Sentry.init({
  dsn: getSentryId(),
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0,
  release: `tertial@${process.env.COMMIT_HASH}`,
});

/** Dersom brukerinfo mangler, sender bruker til påloggingsside */
function RequireAuthentication() {
  const user = getCurrentUser();
  if (user == null) return <Navigate to="/signin" replace />;
  return <Outlet />;
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);
const router = sentryCreateBrowserRouter([
  {
    path: "*",
    element: <App />,
    children: [
      {
        path: routes.signInUidp,
        element: <LogonPage />,
      },
      {
        path: routes.personvern,
        element: <Personvern />,
      },
      {
        element: <RequireAuthentication />,
        children: [
          {
            path: ":orgnummer?/:year?/:tertialNr?",
            element: <StatsforvalterHeader />,
            children: [
              {
                index: true,
                element: <Forside />,
              },
              {
                path: routes.sRapport(":orgnummer", ":year", ":tertialNr"),
                element: <SRapport />,
              },
              {
                path: routes.driftOgProsjekter(":orgnummer", ":year", ":tertialNr"),
                element: <DriftOgProsjekter />,
              },
              {
                path: routes.tilskuddsordninger(":orgnummer", ":year", ":tertialNr"),
                element: <TilskuddsordningRapport />,
              },
            ],
          },
          {
            path: routes.chooseStatsforvalter,
            element: <SelectorStatsforvalter />,
          },
          {
            index: true,
            element: <SelectorStatsforvalter />,
          },
        ],
      },
    ],
  },
]);

const container = document.getElementById("app");
const root = createRoot(container!);

root.render(
  <Sentry.ErrorBoundary>
    <StateProvider reducer={reducer} initState={initialState}>
      <RouterProvider router={router} />
    </StateProvider>
  </Sentry.ErrorBoundary>
);

